var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{staticStyle:{"overflow":"hidden"}},[_c('v-main',{style:([
        _vm.contentDrawer && _vm.$vuetify.breakpoint.mdAndUp
          ? { paddingRight: _vm.drawerRightWidth + 'px' }
          : null,
      ])},[(_vm.scenes)?_c('AppBar',{style:([
        _vm.contentDrawer && _vm.$vuetify.breakpoint.smAndDown
          ? { paddingBottom: _vm.bottomHeight + 'px' }
          : null,
        !_vm.contentDrawer && _vm.currentUUID ? { paddingBottom: '160px' } : null,
        !_vm.contentDrawer && _vm.$vuetify.breakpoint.mdAndUp
          ? { paddingBottom: '0px' }
          : null,
        _vm.contentDrawer && _vm.$vuetify.breakpoint.mdAndUp
          ? { paddingRight: _vm.drawerRightWidth + 'px' }
          : null,
      ])}):_vm._e(),_c('router-view')],1),(_vm.scenes)?_c('DrawerLeft'):_vm._e(),(_vm.currentUUID)?_c('ContentDrawer'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }