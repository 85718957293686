var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-list',{staticClass:"white",attrs:{"three-line":""}},[_c('v-list-item-group',{attrs:{"color":"secondary","mandatory":"","value":_vm.resourceID}},_vm._l((_vm.resources),function(item){return _c('v-list-item',{key:item.id,attrs:{"title":item.type.charAt(0).toUpperCase() +
        item.type.slice(1) +
        ': ' +
        item.title},on:{"click":function($event){$event.stopPropagation();return _vm.openItemByID(item.id, 'no-toggle')}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icon(item))+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.subtitle))])],1),_c('v-list-item-icon',[(item.type === 'audio' && !_vm.isPlaying)?_c('v-icon',[_vm._v(" mdi-play ")]):_vm._e(),(
            item.type === 'audio' && _vm.isPlaying && _vm.currentItem.id === item.id
          )?_c('v-icon',[_vm._v(" mdi-pause ")]):_vm._e(),(
            item.type === 'audio' && _vm.isPlaying && _vm.currentItem.id !== item.id
          )?_c('v-icon',[_vm._v(" mdi-play ")]):_vm._e()],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }